


































































import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { findDataset } from './helpers';
import { hasOwnProperty } from '@movici-flow-common/utils';
import { DGDatasetTypeChild, JsonSchema } from '@/types';
import { datasetGeneratorStore } from '@/store/store-accessor';

@Component({
  name: 'DatasetType',
  components: {}
})
export default class DatasetType extends Vue {
  @Prop({ type: String, required: true }) readonly type!: string;
  @Prop({ type: String, required: true }) readonly display_name!: string;
  @Prop({ type: Object, default: { properties: {} } }) readonly schema!: JsonSchema;
  @Prop({ type: Array, default: () => [] }) readonly childs!: DGDatasetTypeChild[];
  @Prop({ type: String }) readonly parent_type?: string;
  @Prop({ type: String }) readonly child_type?: string;
  @Ref('child_dataset_types') readonly child_dataset_types!: DatasetType[];
  checkBoxSelected = false;
  params: Record<string, unknown> = {};

  get dataset_generator() {
    return datasetGeneratorStore.currentDatasetGenerator;
  }

  get generatorName() {
    return this.dataset_generator?.name;
  }

  get datasetInConfig() {
    if (this.dataset_generator?.datasets?.length) {
      for (let i = 0; i < this.dataset_generator.datasets.length; ++i) {
        let result = findDataset(this.type, this.dataset_generator.datasets[i]);
        if (result !== null) {
          return result;
        }
      }
    }

    return null;
  }

  initializeParameters() {
    const params = {};

    // Set each property to default value
    if (this.schema) {
      for (let property_name in this.schema.properties) {
        if (hasOwnProperty(this.schema.properties, property_name)) {
          let property = this.schema.properties[property_name];
          this.params[property_name] = property.default;
        }
      }
    }

    this.params = params;
  }

  getDatasetPayload() {
    return {
      type: this.type,
      display_name: this.display_name,
      name: this.type + this.getDatasetPostfix(),
      params: this.params
    };
  }

  getDatasetPostfix() {
    if (this.dataset_generator === null) {
      return '';
    }

    let postfix = '' + this.generatorName;

    // replace whitespaces, and replace non-alphanumeric characters with underscores
    // and make lowercase
    postfix = postfix.trim().replace(/[\W_]/g, '_').toLowerCase();
    return '_' + postfix;
  }

  async onPropertyChanged(property_name: string, property_value: unknown) {
    this.params[property_name] = property_value;
    await datasetGeneratorStore.updateGeneratorDataset(this.getDatasetPayload());
  }

  onSelectAll(child_type: string) {
    this.child_dataset_types.forEach(child_dataset_type => {
      if (child_dataset_type.child_type === child_type) {
        child_dataset_type.updateCheckBox(true);
      }
    });
  }

  onDeselectAll(child_type: string) {
    this.child_dataset_types.forEach(child_dataset_type => {
      if (child_dataset_type.child_type === child_type) {
        child_dataset_type.updateCheckBox(false);
      }
    });
  }

  updateCheckBox(selected: boolean) {
    this.checkBoxSelected = selected;
    this.syncToStore().then(() => {});
  }

  async syncToStore() {
    if (this.checkBoxSelected) {
      await datasetGeneratorStore.addGeneratorDataset({
        dataset: this.getDatasetPayload(),
        parent_type: this.parent_type,
        child_type: this.child_type
      });
    } else {
      await datasetGeneratorStore.removeGeneratorDataset(this.type);
    }
  }

  @Watch('generatorName')
  async afterGeneratorName(old_value: string, new_value: string) {
    if (old_value !== new_value && this.checkBoxSelected) {
      await datasetGeneratorStore.updateGeneratorDataset(this.getDatasetPayload());
    }
  }

  @Watch('datasetInConfig', { immediate: true })
  initializeState() {
    if (this.datasetInConfig) {
      this.checkBoxSelected = true;
      this.params = this.datasetInConfig.params;
    } else {
      this.checkBoxSelected = false;
      this.initializeParameters();
    }
  }
}
