


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { DatasetGeneratorLog } from '@/types';
import { UUID } from '@movici-flow-common/types';
import { datasetGeneratorStore } from '@/store/store-accessor';

@Component({ name: 'WorkloadLogs' })
export default class WorkloadLogs extends Vue {
  @Prop({ type: String, required: true }) readonly workload_uuid!: UUID;
  workloadActivityLogs: DatasetGeneratorLog[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  polling: any = null;

  async getWorkloadLogs() {
    this.workloadActivityLogs =
      (await datasetGeneratorStore.getWorkloadActivityLogs(this.workload_uuid)) ?? [];
  }

  pollData() {
    this.polling = setInterval(() => {
      this.getWorkloadLogs();
    }, 3000);
  }

  initialize() {
    this.getWorkloadLogs();
  }

  mounted() {
    this.initialize();
  }

  created() {
    this.pollData();
  }

  beforeDestroy() {
    clearInterval(this.polling);
  }
}
