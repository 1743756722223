















































































import { Component, Prop, Watch } from 'vue-property-decorator';
import {
  CameraOptions,
  CoordinateArray,
  FeatureDrawOption,
  Nullable
} from '@movici-flow-common/types';
import { DatasetGenerator, GeoJSONPolygon } from '@/types';
import { datasetGeneratorStore } from '@/store/store-accessor';
import { EditableGeoJsonLayer } from '@nebula.gl/layers';
import { Feature, Polygon, GeoJsonProperties } from 'geojson';
import { flowStore } from '@movici-flow-common/store/store-accessor';
import { reverseTransformArray, transformArray } from '@movici-flow-common/crs';
import BaseMapControl from '@movici-flow-common/components/map/controls/BaseMapControl.vue';
import DatasetType from './DatasetType.vue';
import Deck from '@movici-flow-common/components/map/Deck.vue';
import DeckContainerMixin from '@movici-flow-common/components/map/DeckContainerMixin';
import defaults from '@movici-flow-common/components/map/defaults';
import NavigationControl from '@movici-flow-common/components/map/controls/NavigationControl.vue';
import Scale from '@movici-flow-common/components/map/controls/Scale.vue';
import orderBy from 'lodash/orderBy';
import FeatureDrawControl from '@movici-flow-common/components/map/controls/FeatureDrawControl.vue';
import bbox from '@turf/bbox';
import { BoundingBox } from '@mapbox/geo-viewport';

@Component({
  name: 'Configurator',
  components: {
    DatasetType,
    Deck,
    NavigationControl,
    BaseMapControl,
    FeatureDrawControl,
    Scale
  }
})
export default class Configurator extends DeckContainerMixin<EditableGeoJsonLayer> {
  @Prop({ type: Object, default: () => null }) readonly value!: DatasetGenerator | null;
  @Prop({ type: Boolean, default: false }) readonly addMode!: boolean;
  polygonFile: File | null = null;
  viewState: Nullable<CameraOptions> = defaults.viewState();
  centerCamera: Nullable<CameraOptions> | null = null;
  showMap = false;
  selectedFeatureIndexes: number[] = [];

  get features(): Feature<Polygon, GeoJsonProperties>[] {
    return this.latLonPolygon ? [this.latLonPolygon] : [];
  }

  set features(val: Feature<Polygon, GeoJsonProperties>[]) {
    let polygon: Polygon | null = null;
    if (val[0]) {
      polygon = {
        coordinates: [reverseTransformArray(val[0].geometry.coordinates[0] as CoordinateArray)],
        type: 'Polygon'
      };
    }
    this.$emit('input', this.getFormattedValue(polygon));
  }

  get hasFeatures() {
    return this.features.length > 0;
  }

  get hasSelected() {
    return this.selectedFeatureIndexes.length > 0;
  }

  get drawOptions(): (Partial<FeatureDrawOption> | string)[] {
    return [
      {
        id: 'draw-polygon',
        enabled: () => !this.hasFeatures,
        icon: 'pencil',
        title: !this.hasFeatures ? 'Draw Polygon' : 'You can draw only one Polygon'
      },
      { id: 'select', enabled: () => this.hasFeatures },
      { id: 'modify', enabled: () => this.hasSelected },
      { id: 'translate', enabled: () => this.hasFeatures && this.hasSelected },
      {
        id: 'transform',
        enabled: () => this.hasSelected,
        title: this.hasSelected ? 'Transform' : 'Select a polygon first'
      },
      {
        id: 'delete',
        enabled: () => this.hasFeatures,
        title: this.hasFeatures ? 'Remove feature' : 'No features to remove'
      }
    ];
  }

  get dataset_types() {
    return datasetGeneratorStore.dataset_types;
  }

  // EPSG:28992
  get polygon(): Feature<Polygon, GeoJsonProperties> | null {
    if (!this.value?.polygon) {
      return null;
    }
    return this.getPolygonFeature(this.value.polygon.coordinates);
  }

  // WGS84
  get latLonPolygon(): Feature<Polygon, GeoJsonProperties> | null {
    if (!this.polygon) {
      return null;
    }
    return this.getPolygonFeature([
      transformArray(this.polygon.geometry.coordinates[0] as CoordinateArray)
    ]);
  }

  get orderedDatasets() {
    return orderBy(this.dataset_types, 'display_name');
  }

  get hasGeocodeCapabilities() {
    return flowStore.hasGeocodeCapabilities;
  }

  async getDatasetTypes() {
    await datasetGeneratorStore.getDGSDatasetTypes();
  }

  getFormattedValue(polygon: Polygon | null, crs = 'EPSG:28992') {
    return {
      ...this.value,
      polygon: polygon
        ? {
            ...polygon,
            crs: {
              properties: {
                name: crs
              },
              type: 'name'
            }
          }
        : null
    };
  }

  @Watch('polygonFile')
  afterPolygonFile(file: File) {
    // emits input (polygon in 28992)
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', event => {
      try {
        const textData = '' + event.target?.result,
          polygon: GeoJSONPolygon = JSON.parse(textData);

        let coordinates = polygon.coordinates;

        if (polygon.crs.properties.name === 'EPSG:28992') {
          coordinates = [transformArray(coordinates[0])];
        }

        this.features = [this.getPolygonFeature(coordinates)];
      } catch (error) {
        this.$flow.snackbar.failMessage('Invalid JSON file');
      }
    });

    reader.addEventListener('error', () => {
      this.$flow.snackbar.failMessage('Could not read selected file');
    });

    reader.addEventListener('loadend', () => {
      this.polygonFile = null;
    });

    reader.readAsText(file);
  }

  getPolygonFeature(
    coordinates: CoordinateArray[],
    properties: GeoJsonProperties = null
  ): Feature<Polygon, GeoJsonProperties> {
    return {
      geometry: {
        coordinates,
        type: 'Polygon'
      },
      properties,
      type: 'Feature'
    };
  }

  zoomToPolygon(polygon: Feature | null) {
    if (polygon) {
      this.deckEl.zoomToBBox(bbox(polygon) as BoundingBox);
      this.centerCamera = this.viewState;
    } else {
      this.centerCamera = null;
    }
  }

  mounted() {
    this.showMap = true;
    this.setController({ doubleClickZoom: false });
    this.getDatasetTypes().then(() => {
      this.zoomToPolygon(this.latLonPolygon);
    });
  }
}
