





















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Configurator from './Configurator.vue';
import ActivityLog from './ActivityLog.vue';
import { EventBus } from '../../eventbus';
import { DatasetGenerator } from '@/types';
import { UUID } from '@movici-flow-common/types';
import { datasetGeneratorStore, projectStore } from '@/store/store-accessor';

const TabIds = ['editor', 'config', 'logs'];

@Component({
  name: 'DatasetGeneratorDetail',
  components: {
    Configurator,
    ActivityLog
  }
})
export default class DatasetGeneratorDetail extends Vue {
  @Prop({ type: String }) readonly uuid?: UUID;
  @Prop({ type: String, default: 'editor' }) readonly requested_tab!: string;
  activeTab = 0;
  errors: string[] = [];

  get project() {
    return projectStore.projects;
  }

  get dataset_generator() {
    return datasetGeneratorStore.currentDatasetGenerator;
  }

  set dataset_generator(val: DatasetGenerator | null) {
    datasetGeneratorStore.setCurrentDatasetGenerator(val);
  }

  get addMode() {
    return !this.uuid;
  }

  get editMode() {
    return !!this.uuid;
  }

  get title() {
    return this.addMode ? this.$t('dataset_generator.addNew') : this.$t('dataset_generator.edit');
  }

  get activeTabId() {
    return TabIds[this.activeTab];
  }

  async initializeThisDatasetGenerator() {
    if (this.editMode && this.uuid) {
      await datasetGeneratorStore.getDatasetGenerator(this.uuid);
    } else {
      await datasetGeneratorStore.setCurrentDatasetGenerator(
        this.getEmptyDatasetGenerator() as DatasetGenerator
      );
    }
  }

  getEmptyDatasetGenerator(): Partial<DatasetGenerator> {
    return {
      name: '',
      datasets: [],
      polygon: undefined
    };
  }

  async validateBeforeSave(value: DatasetGenerator) {
    let resp;

    if (this.editMode) {
      resp = await this.updateDatasetGenerator(value);
    } else {
      resp = await this.addDatasetGenerator(value, false);
    }

    if (resp) {
      this.goBack();
    }
  }

  async onSaveAndGenerate(value: DatasetGenerator) {
    let resp;

    if (this.editMode) {
      resp = await this.updateDatasetGenerator(value);
    } else {
      resp = await this.addDatasetGenerator(value, true);
    }

    if (resp) {
      this.goBack();
    }
  }

  async updateDatasetGenerator(value: DatasetGenerator) {
    const resp = await datasetGeneratorStore.updateDatasetGenerator(value);
    if (resp) {
      this.$flow.snackbar.successMessage('Dataset Generator successfully updated');
    }
    return resp;
  }

  async addDatasetGenerator(value: DatasetGenerator, start_generation: boolean) {
    const resp = await datasetGeneratorStore.addNewDatasetGenerator(value);
    if (resp) {
      this.$flow.snackbar.successMessage('Dataset Generator successfully created');
      if (resp.generator_uuid) {
        if (start_generation) {
          // Also generate datasets directly
          await this.generate(resp.generator_uuid);
        }
      }
    }
    return resp;
  }

  async generate(generator_uuid: string) {
    const message = await datasetGeneratorStore.generate({
      generator_uuid: generator_uuid,
      payload: {}
    });
    this.$flow.snackbar.successMessage(message);
  }

  goBack() {
    this.$router.push({ name: 'DatasetGenerators' });
  }

  addErrors(errors: string[]) {
    if (Array.isArray(errors)) {
      this.errors = errors;
    } else if (typeof errors === 'string') {
      this.errors = [errors];
    } else {
      console.log('invalid error', errors);
    }
  }

  activityLogsDisabled(generator: DatasetGenerator) {
    return generator.last_workload_status === null;
  }

  @Watch('activeTab')
  afterActiveTab() {
    this.errors = [];
    EventBus.$emit('dataset-generators-active-tab', this.activeTabId);
  }

  @Watch('projects')
  afterProjects() {
    if (this.dataset_generator && this.dataset_generator.project_name) {
      projectStore.setActiveProjectName(this.dataset_generator.project_name);
    }
  }

  mounted() {
    this.initializeThisDatasetGenerator().then(() => {
      if (this.requested_tab) {
        this.activeTab = TabIds.indexOf(this.requested_tab);
      }
    });
  }
}
