import { render, staticRenderFns } from "./DatasetGeneratorDetail.vue?vue&type=template&id=791241f5&scoped=true&"
import script from "./DatasetGeneratorDetail.vue?vue&type=script&lang=ts&"
export * from "./DatasetGeneratorDetail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "791241f5",
  null
  
)

export default component.exports