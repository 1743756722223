






















































import { Component, Prop, Vue } from 'vue-property-decorator';
import WorkloadLogs from './WorkloadLogs.vue';
import orderBy from 'lodash/orderBy';
import { DatasetGeneratorWorkload } from '@/types';
import { datasetGeneratorStore } from '@/store/store-accessor';

@Component({
  name: 'ActivityLog',
  components: {
    WorkloadLogs
  }
})
export default class ActivityLog extends Vue {
  @Prop({ type: String }) readonly generator_uuid?: string;
  expandedItem: string[] = [];
  workloads: DatasetGeneratorWorkload[] = [];

  get orderedWorkloads() {
    return orderBy(this.workloads, 'created_on', 'desc');
  }

  async updateWorkloads(generator_uuid: string) {
    this.workloads = (await datasetGeneratorStore.getGeneratorWorkloads(generator_uuid)) ?? [];

    if (this.orderedWorkloads.length > 0) {
      this.expandedItem = [this.orderedWorkloads[0].uuid];
    }
  }

  closeAllOtherDetails(row: DatasetGeneratorWorkload) {
    // Ensure that only one workload is expanded
    this.expandedItem = [row.uuid];
  }

  mounted() {
    if (this.generator_uuid) {
      this.updateWorkloads(this.generator_uuid);
    }
  }
}
